import backend from './backend';
import { routes } from './routes';

type Environment = 'production' | 'staging';

export const ENVIRONMENT: Environment =
    window.location.hostname === 'pickhome-frontend-stage.eu.aldryn.io' || window.location.hostname === 'localhost'
        ? 'staging'
        : 'production';

const STRIPE_KEY_STAGE = 'pk_test_sk52fQL5HDYZqJJg5p2tgIMg';
const STRIPE_KEY_LIVE = 'pk_live_Lec0cUgZIRr1dqAZLtYKfK6t';

const SWISS_POST_BASE_URL_STAGE = 'https://int.post.ch/pah/vintra/ui/order/parcels?key=1234531160';
const SWISS_POST_BASE_URL_LIVE = 'https://www.post.ch/pah/vintra/ui/order/parcels?key=1234531160';

export default {
    backend: backend[ENVIRONMENT],
    stripePublishableKey: ENVIRONMENT === 'production' ? STRIPE_KEY_LIVE : STRIPE_KEY_STAGE,
    countryCode: '41',
    currency: 'chf',
    findStoreURL: 'https://www.stoeckli.ch/chde/shop/filialen-finden',
    locale: 'de',
    availableLocales: ['de'],
    routes,
    surveyMonkeyURL:
        'https://de.surveymonkey.com/r/skiserviceathome-test1?sf_Account_Id=%5bsf_Account_Id_value%5d&sf_Contact_Id=%5bsf_Contact_Id_value%5d',
    swissPostBaseUrl: ENVIRONMENT === 'production' ? SWISS_POST_BASE_URL_LIVE : SWISS_POST_BASE_URL_STAGE,
};
